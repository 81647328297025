import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./InputFields.module.scss";
import { Tooltip } from "antd";
import { DownOutlined } from "@ant-design/icons";

export default function InputLabel({
  active,
  labelText,
  children,
  value,
  isMandatory,
  hintText,
  isHintOverridden,
}) {
  const [dropdownState, setDropdownState] = useState(isHintOverridden);

  // If global value is true and the dropdown is already expanded, do nothing
  // If global value is true and dropdown is collapsed, expand it
  // If global value is false and the dropdown is expanded, collapse it
  // If gloval value is false and the dropdown is collapsed, do nothing
  // If global is true, the dropdown can be collapsed on click
  // If global is false, the dropdown can be expanded on click

  useEffect(() => {
    setDropdownState(isHintOverridden);
  }, [isHintOverridden])

  const handleDropdownExpand = () => {
    if(isHintOverridden && dropdownState) {
      setDropdownState(false)
    } else if(!isHintOverridden && !dropdownState) {
      setDropdownState(true)
    } else if(!isHintOverridden && dropdownState) {
      setDropdownState(false)
    } else if(isHintOverridden && !dropdownState) {
      setDropdownState(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column-reverse",
        position: "relative",
      }}
    >
      {children}
      <span
        className={classNames(styles.label, {
          [styles.floatingLabel]: active || value,
        })}
      >
        {`${labelText}${isMandatory ? " *" : ""}`}
        {dropdownState && hintText?.length > 0 &&  (
          <span className={styles.hintText} dangerouslySetInnerHTML={{__html: hintText}}>
          </span>
        )}
      </span>
      {hintText?.length > 0 && (
        <Tooltip title={"Click to show/hide hint"}>
          <DownOutlined
            className={classNames({
              [styles.dropdownOpen]: dropdownState,
              [styles.dropdownIcon]: true
            })}
            onClick={handleDropdownExpand}
            style={{
              marginLeft: "15px",
              position: "absolute",
              right: "10px",
              top: "16px",
              fontSize: "18px",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}
