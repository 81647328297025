import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reduxBatch } from '@manaflair/redux-batch';
import {
    globalReducer,
} from './reducers/global.reducer';
const history = createBrowserHistory();
history.listen(() => {
    window.scrollTo(0, 0);
});
const store = configureStore({
    reducer: {
        global: globalReducer,
        router: connectRouter(history),
    },
    middleware: [...getDefaultMiddleware(), routerMiddleware(history)],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [reduxBatch],
});

export { store, history };