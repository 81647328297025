import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Link, Redirect, Route, Switch } from 'react-router-dom';


import {
  notification
} from "antd";

import Cookies from 'js-cookie';
import axios from "axios";

import GenerateDocument from './modules/GenerateDocument/index'
import SelectDocument from './modules/SelectDocument';
import Dashboard from './modules/Dashboard'
import Login from './modules/Login';

function App() {
  const history = useHistory();
  const location = useLocation();


  const [profileEmail, setProfileEmail] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [isAuthHeaderSet, setIsAuthHeaderSet] = useState(false);
  const [headerTempate, setHeaderTemplate] = useState(null);

  useEffect(() => {
    const authTokenFromCookie = Cookies.get('auth-token');
    const emailFromCookie = Cookies.get('user-email');
    if (!authToken && !authTokenFromCookie && location.pathname !== '/login') history.push('/login');
    else if (!authToken && authTokenFromCookie) {
      axios.defaults.headers.common['token'] = authTokenFromCookie;
      setAuthToken(authTokenFromCookie);
      setProfileEmail(emailFromCookie);
      setIsAuthHeaderSet(true);
    }
  }, [location, authToken, history]);


  useEffect(() => {
    setHeaderTemplate(
      <div className='app-header'>
        <Link to={"/"}>
          <div className='samvad-logo'></div>
        </Link>
        {authToken && <div className='profile-section'>
          <span className='user-email'>{profileEmail}</span>
          <span className='logout-button' onClick={(() => handleLogoutClick({undefined, undefined, shouldShowError: false}))}>Logout</span>
        </div>}
        <Link className='help-cta' target="_blank" rel="noopener noreferrer" to={"/Document_Genenrator_User%20Guide.pdf"}>
          <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#5271FF"/>
            </svg>
          </div>
          
        </Link>
      </div>
    )
  }, [location, authToken, profileEmail])

  const Loader = () => {
    return (<h1>Loader</h1>)
  }

  const handleLogoutClick = ({ message, description, shouldShowError }) => {
    setAuthToken('');
    setProfileEmail('');
    Cookies.remove('auth-token');
    Cookies.remove('user-email');
    history.push('/login');
    window.location.reload();
    if(shouldShowError) {
      notification.error({
        message: message || 'Session Expired',
        description: description || 'Please login again to continue.'
      });
    }
  }

  const handleLoginSuccess = (payload) => {
    const { token, email } = payload.data;
    setProfileEmail(email || 'NA');
    setAuthToken(token);
    axios.defaults.headers.common['token'] = token;
    Cookies.set('auth-token', token);
    Cookies.set('user-email', email || 'NA');
    setIsAuthHeaderSet(true);
    history.push('/');
  }

  return (
    <React.Suspense fallback={<Loader />}>
      {headerTempate}
      <Switch>
        <Route path={'/generate/:id'} exact>
          <GenerateDocument isAuthHeaderSet={isAuthHeaderSet} forceLogout={handleLogoutClick} />
        </Route>
        <Route path={'/login'}>
          <Login loginSuccess={handleLoginSuccess} forceLogout={handleLogoutClick} />
        </Route>
        <Route path={'/dashboard'}>
          <Dashboard isAuthHeaderSet={isAuthHeaderSet} forceLogout={handleLogoutClick} />
        </Route>
        <Route path={'/'}>
          <SelectDocument isAuthHeaderSet={isAuthHeaderSet} forceLogout={handleLogoutClick} />
        </Route>
        <Redirect to={'/login'} />
      </Switch>
    </React.Suspense>)
}

export default App;