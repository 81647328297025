import React, { useState, useEffect } from "react";
import { Table, Tabs } from 'antd';
import moment from 'moment';

import axios from "axios";
import { API_BASEPATH } from '../../constants';

const { TabPane } = Tabs;

function Dashboard({ isAuthHeaderSet, forceLogout }) {
  const [currentTab, setCurrentTab] = useState('user')
  const [userReportData, setUserReportData] = useState([]);

  useEffect(() => {
    if (isAuthHeaderSet) fetchUsageReports(currentTab)
  }, [isAuthHeaderSet, currentTab]);

  const fetchUsageReports = async (key) => {
    try {
      const response = await axios.post(
        `${API_BASEPATH}/usage_report`, {
        queryMode: key
      });
      setUserReportData(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        forceLogout({
          message: 'Session Expired',
          description: error.response.data
        });
      }
    }
  }

  const handleTabChange = (key) => {
    setCurrentTab(key);
  }

  const userColumns = [
    {
      title: 'User',
      dataIndex: 'emailID',
    },
    {
      title: 'Last Download',
      dataIndex: 'lastDownload',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.lastDownload - b.lastDownload,
      render: ((date) => moment(date).fromNow())
    },
    {
      title: 'No. of Documents Generated',
      dataIndex: 'downloadCount',
      sorter: (a, b) => a.downloadCount - b.downloadCount,
    }];

  const documentColumns = [
    {
      title: 'Document Title',
      dataIndex: 'documentID',
      onFilter: (value, record) => record.documentID.indexOf(value) === 0,
      // sorter: (a, b) => a.documentID.length - b.documentID.length,
      render: ((str) => {
        if (str?.length) {
          let strArr = str.split("_");
          strArr = strArr.map((word) => {
            return word[0].toUpperCase() + word.slice(1)
          });
          return strArr.join(' ');
        }
      })
    },
    {
      title: 'No. of Documents Generated',
      dataIndex: 'downloadCount',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.downloadCount - b.downloadCount
    }];

  const feedabackColumns = [
    {
      title: 'Submitted By',
      dataIndex: 'submittedBy'
    },
    {
      title: 'Submitted On',
      dataIndex: 'submittedAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.submittedAt - b.submittedAt,
      render: ((date) => moment(date).fromNow())
    },
    {
      title: 'Document Title',
      dataIndex: 'documentID',
      sortDirections: ['descend'],
      render: ((str) => {
        if (str?.length) {
          let strArr = str.split("_");
          strArr = strArr.map((word) => {
            return word[0].toUpperCase() + word.slice(1)
          });
          return strArr.join(' ');
        }
      })
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      render: ((str) => {
        return str === 'positive' ? 'Like' : 'Dislike'
      })
    },
    {
      title: 'Comments',
      dataIndex: 'feedbackText'
    }];

  return (
    <div className="App">
      <div className="admin-report-wrapper">

        <Tabs defaultActiveKey="1" onChange={handleTabChange}>
          <TabPane tab="Usage by User" key="user">
            <Table showSorterTooltip={false} columns={userColumns} dataSource={userReportData} sortDirections={["ascend", "descend", "ascend"]}/>
          </TabPane>
          <TabPane tab="Usage by Document" key="document">
            <Table showSorterTooltip={false} columns={documentColumns} dataSource={userReportData} sortDirections={["ascend", "descend", "ascend"]}/>
          </TabPane>
          <TabPane tab="Feedback" key="feedback">
            <Table showSorterTooltip={false} columns={feedabackColumns} dataSource={userReportData} sortDirections={["ascend", "descend", "ascend"]}/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default Dashboard;
