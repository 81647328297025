import React from 'react';
import { Col, Checkbox } from 'antd';

import InputWithLabel from "./../InputWithLabel/index";

function SameAsOtherField({
    field,
    value,
    optionText,
    shouldShowAllHints,
    showErrors,
    checkboxValueChange,
    entityId
}) {
    let isSameCheck = false;
    // const [isSameCheck, setIsSameChecked] = useState(false);
    const handleFreeTextChange = (fieldName, e) => {
        checkboxValueChange(fieldName, isSameCheck, entityId, e.target.value);
    }

    const sameAsOnChange = (fieldName, e) => {
        checkboxValueChange(fieldName, e.target.checked, entityId, value);
    }
    return (
        <Col md={12} key={field.id}>
        <InputWithLabel
            type="text"
            hintText={field?.metadata?.hintText || ""}
            labelText={field.title}
            placeholder={"Enter text"}
            isMandatory={field.is_mandatory}
            className="custom-form-text-input"
            key={field.id}
            value={value}
            onChange={(e) => handleFreeTextChange(field.id, e)}
            isHintOverridden={shouldShowAllHints}
            showErrorsIfErrored={showErrors}
            isDisabled={isSameCheck}
            isCompact={true}
        />
        <Checkbox style={{ "marginBottom": "18px"}} onChange={(e) => {sameAsOnChange(field.id, e); isSameCheck = e.target.checked}}>{optionText}</Checkbox>
        </Col>
    )

    
}

export default SameAsOtherField;