import { createReducer } from '@reduxjs/toolkit';
import {
    action_loaderOff,
    action_loaderOn,
    action_setOffline,
} from './../actions/global'
const initialState = {
    cohortSpecificUserLevelFlags: {},
    initialApplicationSetupDone: false,
    loader: 0,
    offline: false,
    userCohortConfig: {},
    userLevelFlags: {},
};
const globalReducer = createReducer(initialState, {
    [action_loaderOff.toString()]: (state) => {
        state.loader = state.loader > 0 ? state.loader - 1 : 0;
    },
    [action_loaderOn.toString()]: (state) => {
        state.loader += 1;
    },
    [action_setOffline.toString()]: (state, action) => {
        state.offline = action.payload;
    },
});

export { globalReducer };