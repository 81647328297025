import React, { useState, useEffect } from "react";

import axios from "axios";
import Cookies from 'js-cookie';

import { useParams, useLocation } from "react-router-dom";
import { format } from 'date-fns';
import { inWords } from './../../utils/NumberToWords';
import {
  Button,
  Layout,
  Row,
  Col,
  Switch,
} from "antd";
import FileSaver from "file-saver";
import moment from "moment";

import { API_BASEPATH } from "../../constants";

// import FloatingTextField from "../../components/FloatingTextField/index";

import SameAsOtherField from "../../components/SameAsOtherField/index";
import InputWithLabel from "../../components/InputWithLabel/index";
import DropdownInput from "../../components/DropdownInput/index";
import Stepper from "../../components/Stepper/index";
import DownloadModal from "../../components/DownloadModal/index";
import SingleDate from "../../components/SingleDate/index";


function GenerateDocument({ isAuthHeaderSet, forceLogout}) {
  const params = useParams();
  const location = useLocation();
  const [isLoaded, setIsPageLoaded] = useState(false);
  const [formConfig, setFormConfig] = useState({});
  const [formTemplate, setFormTemplate] = useState();
  const [generatorConfig, setGeneratorConfig] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(1);
  const [stepMeta, setStepMeta] = useState([]);
  const [shouldShowAllHints, setShouldShowAllHints] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [entityMapping, setEntityMapping] = useState({});
  const [sameAsReplaceList, setSameAsReplaceList] = useState([]);
  const [replaceToPush, setReplaceToPush] = useState({});

  const [isStorageChecked, setIsStorageChecked] = useState(false);
  const [shouldRetrieveStoredValues, setShouldRetrieveStoredValues] = useState(false);

  const [shouldShowDownloadModal, setShouldShowDownloadModal] = useState(false);
  const [isDocumentInTestingMode, setIsDocumentInTestingMode] = useState(false);

  const [userEmail, setUserEmail] = useState(Cookies.get('user-email'));


  useEffect(() => {
    if (isAuthHeaderSet) {
      if (params.id) fetchFormConfig(params.id);
    }
    const paramString = new URLSearchParams(location.search.slice(1));
    const isInTestingMode = paramString.get('testing_mode') || false;
    setIsDocumentInTestingMode(isInTestingMode);
  }, [location.search, params.id, shouldRetrieveStoredValues, isAuthHeaderSet]);

  useEffect(() => {
    const paramString = new URLSearchParams(location.search.slice(1));
    const storedConfig = retrieveConfigFromStorage();
    let shouldRetrieveConfigFromStorage = false;
    if (paramString.get('restore_config')) {
      shouldRetrieveConfigFromStorage = true;
      setShouldRetrieveStoredValues(true);
    }
    if (shouldRetrieveConfigFromStorage && storedConfig && Object.keys(storedConfig).length) {
      restoreConfig();
      const { currentStep } = storedConfig;
      setCurrentStep(currentStep);
      SetFormForCurrentStep();
    }
    setIsStorageChecked(true);
  }, []);

  useEffect(() => {
    if (Object.keys(formConfig).length && isStorageChecked) {
      SetFormForCurrentStep();
      createEntityIdLinks();
    }
  }, [currentStep, generatorConfig, formConfig, shouldShowAllHints, showErrors, isStorageChecked, isStorageChecked, shouldRetrieveStoredValues]);

  const createEntityIdLinks = () => {
    let entityList = {};
    formConfig.steps.forEach((step) => {
      step.forEach((item) => {
        if (item.entity_id) {
          const entityId = item.entity_id;
          entityList = {
            ...entityList,
            [entityId]: entityList[entityId] ? entityList[entityId] + 1 : 1
          }
        }
      })
    });
    setEntityMapping(entityList)
  }

  const fetchFormConfig = async (documentID) => {
    try {
      const response = await axios.get(
        `${API_BASEPATH}/fields_to_render?documentID=${documentID}`
      );
      setFormConfig(response.data);
      const { steps, stepMetadata } = response.data;
      setTotalSteps(steps.length);
      if (location.search !== '?restore_config=true') setCurrentStep(1);
      setStepMeta(stepMetadata);
      setIsPageLoaded(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        forceLogout({
          message: 'Session Expired',
          description: error.response.data
        });
      }
    }
  };

  const SetFormForCurrentStep = () => {
    if (formConfig && Object.keys(formConfig).length !== 0) {
      let fieldsForCurrentStep = formConfig.steps[currentStep - 1];
      let e = [];
      fieldsForCurrentStep.forEach((field) => {
        const { field_type } = field;
        if (
          (field_type === "free_text" || field_type === "free_text_long") &&
          parseCondition(field.conditions)
        ) {
          e.push(
            <Col md={12} key={field.id}>
              <InputWithLabel
                type={field_type === "free_text" ? "text" : "text_field"}
                hintText={field?.metadata?.hintText || ""}
                labelText={field.title}
                placeholder={"Enter text"}
                isMandatory={field.is_mandatory}
                className="custom-form-text-input"
                key={field.id}
                value={generatorConfig[field.id]}
                onChange={(e) => handleFreeTextChange(field.id, field.is_mandatory, e)}
                isHintOverridden={shouldShowAllHints}
                showErrorsIfErrored={showErrors}
              />
            </Col>
            //  </Row>
          );
        } else if (
          field_type === "free_number" &&
          parseCondition(field.conditions)
        ) {
          e.push(
            <Col md={12} key={field.id}>
              <InputWithLabel
                labelText={field.title}
                placeholder={"Enter number"}
                isMandatory={field.is_mandatory}
                className="custom-form-text-input"
                key={field.id}
                value={generatorConfig[field.id]}
                onChange={(e) => handleFreeNumberChange(field.id, field.type, e)}
                type={"number"}
                subType={field.type}
                hintText={field?.metadata?.hintText || ""}
                isHintOverridden={shouldShowAllHints}
                showErrorsIfErrored={showErrors}
              />
            </Col>
          );
        } else if (field_type === "dropdown" &&
          parseCondition(field.conditions)) {
          e.push(
            <DropdownInput key={field.id} value={generatorConfig[field.id]} title={field.title} fieldId={field.id} isMandatory={field.is_mandatory} showErrors={showErrors} config={generatorConfig[field.id]} handleDynamicSectionChange={handleDynamicSectionChange} dropdownOptions={field.metadata.dropdown_options} isHintOverridden={shouldShowAllHints} hintText={field.metadata.hintText}></DropdownInput>
          );
        } else if (field_type === "date_picker" &&
          parseCondition(field.conditions)) {
          e.push(
            <Col md={12} key={field.id}>
              <SingleDate isHintOverridden={shouldShowAllHints} config={generatorConfig} handleDateChange={handleDateChange} value={generatorConfig[field.id] ? moment(generatorConfig[field.id]) : null} title={field.title} fieldId={field.id} isMandatory={field.is_mandatory} showErrors={showErrors} hintText={field.metadata.hintText}></SingleDate>
            </Col>
          );
        } else if (field_type === "same_as_text" &&
          parseCondition(field.conditions)) {
          let value = generatorConfig[field.id];
          const entityId = field.metadata.sameAsEntity;
          const linkedItem = formConfig.steps[currentStep - 1].filter((field) => {
            return field.id === entityId
          });
          const linkedFieldTitle = linkedItem[0].title;

          e.push(<SameAsOtherField field={field} value={value} checkboxValueChange={handleSameAsOnChange} entityId={entityId} optionText={`Use same value as '${linkedFieldTitle}'`} shouldShowAllHints={shouldShowAllHints} showErrors={showErrors}></SameAsOtherField>)
        }
      });
      setFormTemplate(e);
    }
  };

  const StepActions = () => {
    let template;
    if (currentStep === totalSteps) {
      template = (
        <Row className="mt-10" justify={"space-between"} align={'middle'}>
          <Col>
            <Button className="finish-step-previous-button" disabled={currentStep === 1} onClick={() => handleStepChange(-1)}>Previous</Button>
          </Col>
          <Col>
            <Button
              type={"primary"}
              className="download-button"
              onClick={handleFormSubmit}
            >
              Finish
            </Button>
          </Col>
        </Row>
      );
    } else {
      template = (
        <div className="step-actions">
          <Row justify={"space-between"}>
            <Col>
              <Button disabled={currentStep === 1} onClick={() => handleStepChange(-1)}>Previous</Button>
            </Col>
            <Col>
              <Button onClick={() => handleStepChange(1)}>Next</Button>
            </Col>
          </Row>
        </div>
      );
    }
    return template;
  };

  const handleFreeTextChange = (fieldName, isMandatory, e) => {
    const val = e.target.value;
    if (val.length) {
      let temp = Object.assign(generatorConfig, {
        [fieldName]: val,
      });
      setGeneratorConfig(temp);
    } else {
      let temp = Object.assign(generatorConfig);
      delete temp[fieldName];
      setGeneratorConfig(temp);
    }
    SetFormForCurrentStep();
    checkIfCurrentStepValid();
    handleStorageForFieldChange();
  };


  const handleFreeNumberChange = (fieldName, type, e) => {
    const val = e.target.value;
    let processedString = "";

    if(type === "number_percentage") {
      // Show it as 1% (one percent)
      processedString = `${val}% (${inWords(Number(val))} percent)`;
    } else if (type === "number_price") {
      // Show it as 100 (Rupees hundred only)
      processedString = `Rs. ${Number(val).toLocaleString('en-IN')}/- (Rupees ${inWords(Number(val))} only)`;
    } else {
      processedString = `${val} (${inWords(Number(val))})`;
    }
    let temp = Object.assign(generatorConfig, {
      [fieldName]: processedString,
    });
    if(!val.length) {
      temp = Object.assign(generatorConfig, {
        [fieldName]: undefined,
      });
    }
    setGeneratorConfig(temp);
    SetFormForCurrentStep();
    checkIfCurrentStepValid();
    handleStorageForFieldChange();
  };

  const handleDynamicSectionChange = (fieldName, isMandatory, e) => {
    let temp = Object.assign(generatorConfig, {
      [fieldName]: e,
    });
    setGeneratorConfig(temp);
    SetFormForCurrentStep();
    checkIfCurrentStepValid();
    handleStorageForFieldChange();
  };

  const handleDateChange = (fieldName, isMandatory, e) => {
    const selectedDate = moment(e).format("MMMM D, YYYY");
    let temp = Object.assign(generatorConfig, {
      [fieldName]: selectedDate,
    });
    setGeneratorConfig(temp);
    SetFormForCurrentStep();
    checkIfCurrentStepValid();
    handleStorageForFieldChange();
  };

  const handleStorageForFieldChange = () => {
    window.localStorage.setItem(params.id, JSON.stringify({ ...generatorConfig, currentStep: currentStep }));
  }

  const retrieveConfigFromStorage = () => {
    const config = window.localStorage.getItem(params.id);
    return JSON.parse(config)
  }

  const clearConfigFromStorage = () => {
    window.localStorage.removeItem(params.id)
  }

  useEffect(() => {
    let temp = sameAsReplaceList.concat(replaceToPush);
    setSameAsReplaceList(temp)
  }, [replaceToPush])

  const handleSameAsOnChange = (fieldName, shouldReplicate, fieldIdToReplicateFrom, value) => {
    if (shouldReplicate) {
      setReplaceToPush({
        [fieldName]: fieldIdToReplicateFrom
      })
    } else {
      let temp = Object.assign(generatorConfig, {
        [fieldName]: value,
      });
      setGeneratorConfig(temp);
    }
    SetFormForCurrentStep();
    checkIfCurrentStepValid();
  }

  const handleStepChange = (direction) => {
    if (direction === 1) {
      if (checkIfCurrentStepValid()) {
        setCurrentStep(currentStep + 1);
        setShowErrors(false);
      } else {
        setShowErrors(true);
      }
    } else {
      setCurrentStep(currentStep - 1);
      setShowErrors(false);
    }
  };

  const handleHintsToggle = (e) => {
    setShouldShowAllHints(e);
  }

  const parseCondition = (conditionObject) => {
    // Some fields are hidden because of conditions and these fields could be mandatory
    // This function checks if the field is hidden so that the validation can be skipped
    if (conditionObject == null) return true;
    else {
      let e = false;
      Object.entries(conditionObject).forEach((condition) => {
        if (condition[1] === generatorConfig[condition[0]]) e = true;
        else e = false;
      });
      return e;
    }
  };

  const checkIfCurrentStepValid = () => {
    let isValid = true;
    const { steps } = formConfig;
    const fieldsInCurrentStep = steps[currentStep - 1];
    fieldsInCurrentStep.forEach((field) => {
      if (parseCondition(field.conditions) && field.is_mandatory && !generatorConfig[field.id]) {
        // Means the field is required and no input is provided
        isValid = false;
      }
      if(field.field_type === 'free_number') {
        // Checks if a number field has a negative input
        const val = generatorConfig[field.id];
        if(val !== undefined) {
          let valNumber = Number(val.split(" ")[0]);
          if(field.type === 'number_percentage') {
            valNumber =  Number(val.split("%")[0]);
          } else if(field.type === 'number_price') {
            valNumber = Number(val.split("/-")[0].slice(4));
          }
          if(valNumber <= 0) {
            isValid = false;
          } else if(field.is_mandatory && val == undefined) {
            isValid = false;
          } else if(val.split(".").length > 1 && val.slice(0,3) !== 'Rs.') {
            // Decimal values are not supported
            isValid = false;
          }
        }
      }
    });
    return isValid;
  }

  const handleDownloadFile = async () => {
    // Before sending the config to the server, the FE checks if there are any values thhat need to be duplicated because a user checked 'yes' to 'use same value..'

    try {
      const emailFromCookie = Cookies.get('user-email');
      const response = await axios.post(`${API_BASEPATH}/download`, {
        documentID: params.id,
        config: generatorConfig,
        entities: entityMapping,
        userEmail: emailFromCookie || 'NA'
      });
      const str = Buffer.from(response.data, "base64");

      let blob = new Blob([str], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessing",
      });
      const formattedDate = format(new Date(), 'ddMMyy')
      FileSaver.saveAs(blob, `${formConfig.templateName}_SP_${formattedDate}.docx`);
    } catch (error) {
      if (error?.response?.status === 401) {
        forceLogout({
          message: 'Session Expired',
          description: error.response.data
        });
      }
    }
  };

  const restoreConfig = () => {
    const storedConfig = retrieveConfigFromStorage();
    delete storedConfig.currentStep
    setGeneratorConfig(storedConfig);
    SetFormForCurrentStep();
    setShouldRetrieveStoredValues(true);
  }

  const handleFormSubmit = () => {
    const isFormValid = checkIfCurrentStepValid();
    if (isFormValid) {
      setShouldShowDownloadModal(true);
      window.localStorage.removeItem(params.id);
    } else {
      setShowErrors(true);
    }
  };

  return (
    <div className="App">
      {isLoaded ? (
        <>
          <div className="layout-adjuster">
            <Layout className="form-wrapper">
              <Row gutter={20} className="info-row" justify={'space-between'} align={'middle'}>
                <Col md={16}>
                  <h2>{stepMeta[currentStep - 1].title}</h2>
                </Col>
                <Col md={8}>
                  <div className="hint-override-switch-wrapper">
                    <span className="switch-text">{shouldShowAllHints ? 'Showing' : 'Hiding'} All Guides</span>
                    <Switch
                      checkedChildren="Show"
                      unCheckedChildren="Hide"
                      defaultChecked
                      onChange={(e) => handleHintsToggle(e)}
                    />
                  </div>
                </Col>
              </Row>
              <Row justify={"start"} gutter={50}>
                {/* <Col className="mt-10"> */}
                {formTemplate}
                {/* </Col> */}
              </Row>
              <StepActions />
            </Layout>
          </div>
          <Stepper
            currentStep={currentStep - 1}
            stepMeta={stepMeta}
            stepCompletion={""}
          />
          <DownloadModal documentID={params.id} emailID={userEmail} shouldShowClipboard={isDocumentInTestingMode} config={generatorConfig} downloadFile={handleDownloadFile} dismissModal={() => setShouldShowDownloadModal(false)} shouldShowModal={shouldShowDownloadModal}></DownloadModal>

        </>
      ) : (
        <h1>Loading</h1>
      )}
    </div>
  );
}

export default GenerateDocument;
