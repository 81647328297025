import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';

import { GoogleLogin } from 'react-google-login';


import { Row, Col, Button, Form, Input } from 'antd';
import OTPInput, { ResendOTP } from "otp-input-react";
import {setCookie} from "./../../utils/cookies"

import Cookies from 'js-cookie';


import { API_BASEPATH } from '../../constants';


function Login({ loginSuccess }) {

    const [enteredEmail, setEnteredEmail] = useState(false);
    const [isOTPSent, setIsOTPSent] = useState(false);
    const [OTP, setOTP] = useState("");
    const [isOTPValid, setIsOTPValid] = useState(false)

    const [isEnteredEmailValid, setIsEnteredEmailValid] = useState(false);
    const [isLoginErrored, setIsLoginErrored] = useState(false)

    const handleLoginSuccess = (response) => {
        console.log(response)
        // console.log(response);
        loginSuccess(response)
    }

    const handleLoginError = (response) => {
        console.log(response)
    }


    const generateOTP = async () => {
		try {
            const response = await axios.post(
                `${API_BASEPATH}/otp_login`, {
                email: enteredEmail
            });
            if (response.status === 200) {
                setIsOTPSent(true)
            } 
        } catch (err) {
            setIsLoginErrored(true)
		}
    }

    const onBlur = (e) => {
        console.log(e)
    }


    const onFocus = (e) => {
        console.log(e)
    }


    const handleOnChange = (e) => {
        const value = e.target.value;
        setEnteredEmail(value)
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            setIsEnteredEmailValid(true);
        } else setIsEnteredEmailValid(false);
    }

    const handleOTPSubmit = async () => {
        const response = await axios.post(
            `${API_BASEPATH}/verify_otp`, {
            email: enteredEmail,
            OTP: OTP
        }
        );
        setCookie('auth-token', response.data.token);
        setCookie('user-email', response.data.email);
        handleLoginSuccess(response);
    }


    return (
        <div className="App">
            <div className="select-screen-sidebar">
                <div className="document-illustration"></div>
            </div>
            <div className="document-select-wrapper">
                <div className="secondary-logo"></div>

                <div className="login-wrapper">
                    <Row justify="center"><h3>Login with your Samvād: Partners Email Account</h3></Row>
                    {/* <GoogleLogin
                        clientId="751764524425-u5si6gjhubredntisheiirokavb016l3.apps.googleusercontent.com"
                        buttonText="Sign-in to Continue"
                        onSuccess={handleLoginSuccess}
                        onFailure={handleLoginError}
                        cookiePolicy={'single_host_origin'}
                    /> */}
                    {!isOTPSent ? <Row justify={'center'}>

                        <Col md={1}></Col>
                        <Col md={16}>
                            <Input onChange={(e) => handleOnChange(e)} className="login-input" size="large" placeholder="john@example.com" prefix={<UserOutlined />} />
                        </Col>
                        <Col md={6}>
                            <Button disabled={!isEnteredEmailValid} className="login-button" key="back" onClick={generateOTP}>
                                Generate OTP
                            </Button>
                        </Col>
                        <Col>
                            {isLoginErrored && <span className="login-error" style={{'paddingTop': '20px', 'display': 'block', 'color': '#ff0000'}}>Please sign in from an approved domain to continue.</span>}</Col>
                    </Row> : <Row justify={'center'}>
                            <span style={{'paddingBottom': '20px', 'display': 'block'}}>An OTP has been sent to <a href={`mailto{}`}>{enteredEmail}</a></span>
                        <Col>
                            <OTPInput value={OTP} onChange={(e) => {
                                setOTP(e);
                                if(e.length == 6) setIsOTPValid(true)
                            }} autoFocus OTPLength={6} otpType="number" disabled={false} />
                        </Col>
                        <Col>
                        <Button disabled={!isOTPValid} className="login-button" key="back" onClick={handleOTPSubmit}>
                                Submit OTP
                            </Button>
                        </Col>
                    </Row>}
                </div>
            </div>
        </div>
    )
}

export default Login;
