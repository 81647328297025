import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import axios from "axios";
import Lottie from "react-lottie";
import { Row, Modal, Form, Input, Button } from "antd";
import {
  CopyOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import animationData from "./../../assets/download_lottie.json";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { API_BASEPATH } from "./../../constants";

import styles from "./DownloadModal.module.scss";

const { TextArea } = Input;
export default function DownloadModal({
  dismissModal,
  shouldShowModal,
  downloadFile,
  config,
  shouldShowClipboard,
  documentID,
  emailID
}) {

  const history = useHistory();
  const [modalTitle, setModalTitle] = useState("");
  const [template, setTemplate] = useState([]);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [thumbsUpDown, setThumbsUpDown] = useState(0);
  const [feedbackText, setFeedbackText] = useState("");
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    let textToReturn = "";
    if (!isDownloaded) textToReturn = "Preparing your file..";
    else if (isDownloaded && !isFeedbackSubmitted) textToReturn = "Download Complete";
    else if (isDownloaded && isFeedbackSubmitted) textToReturn = "Thank you for your feedback";
    setModalTitle(textToReturn)

  }, [isDownloaded, isFeedbackSubmitted])

  useEffect(() => {
    if (!shouldShowModal) {
      setTemplate(<></>);
      setIsDownloaded(false);
    } else if (shouldShowModal && !isDownloaded) {
      setTemplate(
        <>
          <Lottie options={defaultOptions} height={400} width={400} />
        </>
      );
      setTimeout(() => {
        downloadFile();
      }, 1500);

      setTimeout(() => {
        setIsDownloaded(true);
      }, 3000);
    }
  }, [shouldShowModal, isDownloaded]);

  const handleFeedbackSubmit = () => {
    const feedbackObj = {
      rating: thumbsUpDown,
      feedbackText: feedbackText,
      submittedBy: emailID,
      documentID: documentID
    };
    axios.post(`${API_BASEPATH}/collect_feedback`, feedbackObj).then((response) => {
      setIsFeedbackSubmitted(true);
      setFeedbackText("");
      setThumbsUpDown(0);
    })
  }

  const handleFeedbackChange = (type) => {
    if (type === 'like') setThumbsUpDown(1)
    else setThumbsUpDown(-1)
  }

  const handleFeedbackTextChange = (e) => {
    setFeedbackText(e.target.value)
  }

  return (
    <>
      <Modal
        onOk={dismissModal}
        onCancel={dismissModal}
        className="micro-interaction-modal"
        visible={shouldShowModal}
        footer={[]}
      >


          <h3 className={styles.backToHome} onClick={() => history.push('/')}>
            <ArrowLeftOutlined style={{ fontSize: '22px', color: '#5271FF', marginRight: '12px' }} />
            Go Home</h3>

        <h3 className={styles.modalTitle}>{modalTitle}</h3>
        <div className={`${styles.animationWrapper} ${isDownloaded ? styles.downloadComplete : ''}`}>
          {template}
          <hr />
        </div>
        {isDownloaded && !isFeedbackSubmitted && <><div className={styles.downloadCompleteInfo}>

          <h4>Help us improve the product by telling us about your experience</h4>

          {shouldShowClipboard && <Row justify={'center'}>
            <CopyToClipboard text={JSON.stringify(config)}
              onCopy={() => {setIsCopiedToClipboard(true); setTimeout(() => {
                setIsCopiedToClipboard(false)
              }, 3000);}}>
              <span className={styles.copyToClipboard}>
              <CopyOutlined style={{ fontSize: '18px', color: '#5271FF' }} />
                {isCopiedToClipboard ? "Config Copied to Clipboard!" : "Copy Config"}
              </span>
            </CopyToClipboard>
          </Row>}
          <Row>
          </Row>
          <div className={styles.feedbackWrapper}>
            <div className={styles.thumb} onClick={() => handleFeedbackChange('like')}>
              {thumbsUpDown === 1 ? <svg className="filled" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5271FF"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z" /></svg> : <svg className={styles.unfilled} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5271FF"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" /><path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z" /></svg>}
            </div>
            <div className={styles.thumbsDown} onClick={() => handleFeedbackChange('dislike')}>
              {thumbsUpDown === -1 ? <svg className="filled" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5271FF"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z" /></svg> : <svg className={styles.unfilled} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5271FF"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" /><path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z" /></svg>}
            </div>
            <div className={styles.feedbackTextBox}>
              {thumbsUpDown !== 0 && <>
                <Form.Item name="feedback">
                  <TextArea placeholder="Enter Text" showCount maxLength={100} onChange={handleFeedbackTextChange} />
                </Form.Item>

                <Button onClick={handleFeedbackSubmit}>Submit</Button>
              </>}
            </div>
          </div>
        </div>
        </>
        }

        {isDownloaded && isFeedbackSubmitted && <div className={styles.feedbackSubmittedInfo}>
          <h3>Your feedback has been collected.</h3>
        </div>}
      </Modal>
    </>
  );
}
