import React from 'react';
import { Steps } from 'antd';

import styles from "./Stepper.module.scss";

const { Step } = Steps;


export default function Stepper({
    currentStep,
    stepMeta,
    stepCompletion
  }) {
    const e = [];
    stepMeta.forEach((item, index) => {
        e.push(<Step key={index} title={item.title} />)
    })
    return (
        <div className={styles.stepWrapper}>
            <Steps className={styles.stepComponent} current={currentStep}>
                {e}
            </Steps>
        </div>
    );
  }
  