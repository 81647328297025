import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Cookies from 'js-cookie';
import { Row, Col, Card, Button, Modal } from 'antd';

import axios from "axios";
import { API_BASEPATH } from '../../constants';

function SelectDocument({ isAuthHeaderSet, forceLogout }) {
    const history = useHistory();
    const [documentTypes, setDocumentTypes] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState('');
    const [shouldShowRestoreModal, setShouldShowRestoreModal] = useState({});
    const [shouldRestoreConfig, setShouldRestoreConfig] = useState(false);
    const [isSelectedDocumentInTestingMode, setIsSelectedDocumentInTestingMode] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (isAuthHeaderSet) {
            fetchAvailableDocumentTypes();
            checkIfUserIsAdmin();
        }
    }, [isAuthHeaderSet]);

    const checkIfUserIsAdmin = async () => {
        try {
            const emailFromCookie = Cookies.get('user-email');
            const response = await axios.get(
                `${API_BASEPATH}/is_admin?email=${emailFromCookie}`
            );
            setIsAdmin(response.data.isAdmin)
        } catch (error) {
            if (error?.response?.status === 401) {
                forceLogout({
                  message: 'Session Expired',
                  description: error.response.data
                });
            }
        }
    }


    const fetchAvailableDocumentTypes = async () => {
        try {
            const response = await axios.get(
                `${API_BASEPATH}/available_documents`
            );
            let documents = response.data;
            if (window.location.hostname !== 'localhost') {
                documents = response.data.filter((item) => item.isEnabledOnProd);
            }
            setDocumentTypes(documents);
        } catch (error) {
            if (error?.response?.status === 401) {
                forceLogout({
                  message: 'Session Expired',
                  description: error.response.data
                });
            }
        }
    }

    const handleDocumentSelect = (doc) => {
        setSelectedDocument(doc.documentID);
        setIsSelectedDocumentInTestingMode(doc.isTestingModeEnabled);

        documentTypes.forEach((item) => {
            const storedConfigs = JSON.parse(window.localStorage.getItem(item.documentID));
            if (storedConfigs && Object.keys(storedConfigs).length) {
                setShouldShowRestoreModal(item.documentID)
            }
        })
    }

    const handleRestoreConfig = () => {
        setShouldRestoreConfig(true);
        setShouldShowRestoreModal({});
    }

    const handleDeleteConfig = () => {
        window.localStorage.removeItem(shouldShowRestoreModal);
        setShouldShowRestoreModal({});
    }

    const handleDashboardRoute = () => {
        history.push('/dashboard');
    }

    const handleFinalProceed = () => {
        if (shouldRestoreConfig && isSelectedDocumentInTestingMode) {
            history.push(`/generate/${selectedDocument}?restore_config=true&testing_mode=true`)
        } else if (shouldRestoreConfig && !isSelectedDocumentInTestingMode) {
            history.push(`/generate/${selectedDocument}?restore_config=true`)
        } else if (!shouldRestoreConfig && isSelectedDocumentInTestingMode) {
            history.push(`/generate/${selectedDocument}?testing_mode=true`)
        } else history.push(`/generate/${selectedDocument}`)
    }

    const doucmentCards = () => {
        let e = [];
        if (documentTypes.length) {
            documentTypes.forEach((item) => {
                e.push(<Col md={12}>
                    <Card className={`document-card ${selectedDocument === item.documentID ? 'selected' : ''}`} onClick={() => handleDocumentSelect(item)}>
                        <div className="checkbox"></div>
                        <h3>{item.documentTitle}</h3>
                    </Card>
                </Col>)
            });

            return (
                <Row gutter={[20, 20]}>
                    {e}
                </Row>
            )
        }

    }

    return (
        <div className="App">
            <div className="select-screen-sidebar">
                <h1>Select a Document</h1>
                <div className="document-illustration"></div>
            </div>
            <div className="document-select-wrapper">
                <div className="secondary-logo"></div>
                {isAdmin && <div className="admin-dashboard-route" onClick={handleDashboardRoute}>Dashboard</div>}
                {/* <h1 className="mb-12">Select a document type</h1> */}
                {doucmentCards()}
                <Row justify={'end'}>
                    <Button disabled={!selectedDocument} onClick={handleFinalProceed} type="primary" className="next-step-button">Proceed</Button>
                </Row>
            </div>
            <Modal visible={Object.keys(shouldShowRestoreModal).length}
                className="default-modal"
                onOk={handleRestoreConfig}
                onCancel={handleDeleteConfig}
                footer={[
                    <Row justify={'center'} align={'middle'}>
                        <Button key="back" onClick={handleDeleteConfig}>
                        Start New Session
                    </Button>,
                    <Button className="btn-normal" key="submit" type="primary" onClick={handleRestoreConfig}>
                        Restore Previous Session
                    </Button>
                    </Row>
                ]}>
                <p>You didn't download a docuemnt during your last session. Would you like to start from where you left off?</p>
            </Modal>
        </div>
    )
}

export default SelectDocument;
