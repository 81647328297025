import React, { useState, useEffect } from "react";
import InputLabel from "./FloatingLabel";
import { Form, Input } from "antd";

const { TextArea } = Input;

export default function TextInput({
  name,
  placeholder,
  type,
  onChange,
  isMandatory,
  hintText,
  isHintOverridden,
  value,
  showErrorsIfErrored,
  isDisabled,
  isCompact = false,
  subType,
  ...otherProps
}) {
  const [active, setActive] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [isNumberError, setIsNumberError] = useState(false);
  const [isDecimalError, setIsDecimalError] = useState(false);
  const [template, setTemplate] = useState([]);

  useEffect(() => {
    // If required and no value, show "field is required" error
    if (isMandatory && showErrorsIfErrored && !value) {
      setShouldShowError(true);
    }
    // If field type is number and value is less than 0, show "bad input error"
    else if (type === 'number' && value !== undefined && showErrorsIfErrored) {
      let val = null;
      if(subType === 'number_percentage') val = value.split("%")[0];
      else if(subType === 'number_price') {
        val = value.split("/-")[0].slice(4);
      }
      else val = value.split(" ")[0];

      const valueAsNumber = Number(val);
      if (valueAsNumber <= 0) {
        setShouldShowError(true);
        setIsNumberError(true);
      } else if(val.split(".").length > 1) {
        // Decimal error
        setShouldShowError(true);
        setIsDecimalError(true);
      }
    }
    else setShouldShowError(false)
  }, [isMandatory, showErrorsIfErrored, value])

  const onBlur = () => {
    setActive(false);
  };

  const onFocus = () => {
    setActive(true);
  };
  useEffect(() => {
    if (type === 'number' || type === 'text') {
      let defaultVal = value;
      if(type === 'number' && value) {
        if(subType === 'number_percentage') defaultVal = value.split("%")[0];
        else if(subType === 'number_price') {
          let temp = value.split("/-")[0].slice(4);
          defaultVal = temp.replaceAll(",", "");
        }
        else defaultVal = Number(value?.split(" ")[0]);
      } else if (type ==='number' && !value?.length) defaultVal = null;
      setTemplate(<Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[name] !== currentValues[name]
        }
      >
        {({ getFieldValue }) => (
          <InputLabel active={active} value={getFieldValue(name)} isMandatory={isMandatory} hintText={hintText} isHintOverridden={isHintOverridden} {...otherProps}>
            <Form.Item className={isCompact ? 'compact-form' : ''} name={name}>
              <Input
                onBlur={onBlur}
                onFocus={onFocus}
                style={{ height: "45px" }}
                placeholder={placeholder}
                onChange={onChange}
                type={type || 'text'}
                className={shouldShowError ? 'errored' : ''}
                disabled={isDisabled}
                defaultValue={defaultVal}
              />
              {shouldShowError && !isNumberError && !isDecimalError && <span className="error-text">This field is required</span>}
              {shouldShowError && isNumberError && !isDecimalError && <span className="error-text">Zero, negative numbers and decimals are not supported</span>}
              {shouldShowError && isDecimalError && <span className="error-text">Zero, negative numbers and decimals are not supported</span>}            
            </Form.Item>
          </InputLabel>
        )}
      </Form.Item>)
    } else if (type === 'text_field') {
      setTemplate(
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues[name] !== currentValues[name]
          }
        >
          {({ getFieldValue }) => (
            <InputLabel active={active} value={getFieldValue(name)} isMandatory={isMandatory} isHintOverridden={isHintOverridden} hintText={hintText} {...otherProps}>
              <Form.Item name={name}>
                <TextArea
                  onBlur={onBlur}
                  onFocus={onFocus}
                  style={{ height: "45px" }}
                  placeholder={placeholder}
                  onChange={onChange}
                  type={type || 'text'}
                  className={shouldShowError ? 'errored' : ''}
                  disabled={isDisabled}
                  defaultValue={value}
                />
                {shouldShowError && <span className="error-text">This field is required</span>}
              </Form.Item>
            </InputLabel>
          )}
        </Form.Item>
      )
    }
  }, [value, isHintOverridden, shouldShowError, showErrorsIfErrored]);

  return (
    <>
      {template}
    </>
  )
}
