import React, { useState, useEffect } from 'react';
import { Tooltip, DatePicker } from 'antd'
import { DownOutlined } from "@ant-design/icons";
import classNames from "classnames";


import styles from "./SingleDate.module.scss"

export default function SingleDate({
  title,
  fieldId,
  isMandatory,
  showErrors,
  config,
  dropdownOptions,
  handleDateChange,
  isHintOverridden,
  hintText,
  value
}) {
  const [dropdownState, setDropdownState] = useState(isHintOverridden);

  useEffect(() => {
    setDropdownState(isHintOverridden);
  }, [isHintOverridden])

  const handleDropdownExpand = () => {
    if (isHintOverridden && dropdownState) {
      setDropdownState(false)
    } else if (!isHintOverridden && !dropdownState) {
      setDropdownState(true)
    } else if (!isHintOverridden && dropdownState) {
      setDropdownState(false)
    } else if (isHintOverridden && !dropdownState) {
      setDropdownState(true);
    }
  };

  return (
    <div className="date-picker-wrapper">
      <span className={styles.label}>{`${title}${isMandatory ? " *" : ""
        }`}</span>

      {dropdownState && hintText?.length > 0 && (
        <span className={styles.hintText} dangerouslySetInnerHTML={{ __html: hintText }}>
        </span>
      )}
      
      <DatePicker defaultValue={value} className={isMandatory && showErrors && !config[fieldId] ? 'errored' : ''} onChange={(e) => handleDateChange(fieldId, isMandatory, e)} />
      {isMandatory && showErrors && !config[fieldId] && <span className="date-picker-error-text">This field is required</span>}


      {hintText?.length > 0 && (
        <Tooltip title={"Click to show/hide hint"}>
          <DownOutlined
            className={classNames({
              [styles.dropdownOpen]: dropdownState,
              [styles.dropdownIcon]: true
            })}
            onClick={handleDropdownExpand}
            style={{
              marginLeft: "15px",
              position: "absolute",
              right: "38px",
              top: "20px",
              fontSize: "18px",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      )}
    </div>
  );

}