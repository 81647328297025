import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import { Provider } from 'react-redux';

import { store, history } from './store.js';



import './styles/variables.scss';
import 'antd/dist/antd.css';
import './styles/index.scss'


ReactDOM.render(
    <>
        {/* <ErrorBoundary> */}
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>
        {/* </ErrorBoundary> */}
    </>,
    document.getElementById('root'),
);
// serviceWorker.register();