import React, { useState, useEffect } from 'react';
import { Col, Select, Tooltip } from 'antd'
import { DownOutlined } from "@ant-design/icons";
import classNames from "classnames";


import styles from "./DropdownInput.module.scss"

const { Option } = Select;
export default function DropdownInput({
    title,
    fieldId,
    isMandatory,
    showErrors,
    config,
    dropdownOptions,
    handleDynamicSectionChange,
    isHintOverridden,
    hintText,
    value
}) {
  const [dropdownState, setDropdownState] = useState(isHintOverridden);

  useEffect(() => {
    setDropdownState(isHintOverridden);
  }, [isHintOverridden])

  const handleDropdownExpand = () => {
    if(isHintOverridden && dropdownState) {
      setDropdownState(false)
    } else if(!isHintOverridden && !dropdownState) {
      setDropdownState(true)
    } else if(!isHintOverridden && dropdownState) {
      setDropdownState(false)
    } else if(isHintOverridden && !dropdownState) {
      setDropdownState(true);
    }
  };

    let options = [];
      dropdownOptions.forEach((item, index) => {
        options.push(<Option value={item.key} key={index}>{item.title}</Option>);
      });
      return (
        <Col md={12} key={fieldId}>
          <span className="dropdown-label">{`${title}${
            isMandatory ? " *" : ""
          }`}</span>

          
        {dropdownState && hintText?.length > 0 &&  (
          <span className={styles.hintText} dangerouslySetInnerHTML={{__html: hintText}}>
          </span>
        )}

          <Select defaultValue={value} className={isMandatory && showErrors && !config ? 'errored' : ''} onChange={(e) => handleDynamicSectionChange(fieldId, isMandatory, e)}>
            {options}
          </Select>
            {isMandatory && showErrors && !config && <span className="dropdown-error-text">This field is required</span>}

            {hintText?.length > 0 && (
              <Tooltip title={"Click to show/hide hint"}>
                <DownOutlined
                  className={classNames({
                    [styles.dropdownOpen]: dropdownState,
                    [styles.dropdownIcon]: true
                  })}
                  onClick={handleDropdownExpand}
                  style={{
                    marginLeft: "15px",
                    position: "absolute",
                    right: "10px",
                    top: "16px",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            )}
        </Col>
      );

  }